import {Header} from "../components/Header";
import {Navbar} from "../components/Navbar";
import {Cover} from "../components/Cover";
import {Solutions} from "../components/Solutions";
import {Pricing} from "../components/Pricing";
import {Docs} from "../components/Docs"
import {About} from "../components/About";
import {Footer} from "../components/Footer";
import {Contact} from "../components/Contact";

export const Home = () => {
// function Home() {
    return (
        <div>
        <Header />
        <Navbar />
        <Cover />
        <Solutions />
        <Pricing />
        <Docs />
        <About />
        <Contact />
        <Footer />
        </div>
    );
}
