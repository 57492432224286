import React from "react";
import temp from "../assets/li10-footprint.webp"

export class Cover extends React.Component {
  render() {
    return (
      <div
        className="site-blocks-cover"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12">
              <div className="d-none d-lg-block">
                <img src={temp} title="Li10 footprint" alt="Li10 footprint" className="img-fluid img-absolute"></img>
              </div>
              <div className="row">
                <div className="col-lg-4 mt-4 pt-5">
                  <h1>Lighten your carbon footprint, optimize your cloud!</h1>
                  <p>We are dedicated to helping organizations reduce the carbon footprint associated with cloud computing.</p>
                  <p><li><a href="/#co2-monitor" className="hover-underline"><b>Li10 CO2 Monitor</b></a> measures your cloud workloads environmental footprint and rates it with
                    a <b>Cloud Sustainability Score</b> on a real-time dashboard.</li></p>

                  <p><li><a href="/#li10-usage-report" className="hover-underline"><b>Li10 Usage Report</b></a> provides accountability and transparency to cloud users by generating individual usage reports.</li></p>

                  <p><li><a href="/#li10-governance" className="hover-underline"><b>Li10 Governance</b></a> automates cloud infrastructure audit and remediation
                    to improve your cloud governance posture, reduce risk, cost and environmental footprint.</li></p>
                  {/* <div>
                    <a href="https://app.li10.com" className="text-center btn btn-primary m-2"><b>Get started now!</b></a>
                  </div> */}
                  <div className="text-center text-lg-left m-4">
                    <a href="https://app.li10.com" className="btn btn-primary m-2">
                      <b>Get started now!</b>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
