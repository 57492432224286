import {Header} from "../../components/Header";
import {Navbar} from "../../components/Navbar";

// function PrivacyPolicy() {
  export const Legal = () => { 
    return (
        <div>
        <Header />
        <Navbar />
        <div className="site-blocks-cover">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div className="row mb-4">
                
                  <div className="d-flex align-items-start flex-column">
                  <br /><br /><br />
                    <h1>Legal Information</h1>
                    <p>
                      This website is operated by:<br />
                      <b>Li10 UG</b><br />
                      <b>Lenautrasse 33</b><br />
                      <b>40470 Düsseldorf</b><br />
                      <b>Germany</b><br />
                      <br />
                      Managing Director: <b>Matthias Büchner</b><br />

                      {/* Registration Court: <b>Amtsgericht Düsseldorf</b><br />
                      Registration Number: <b>XXX XXXX</b><br />
                      VAT Number: <b>DE XXXXXX</b><br /> */}

                    </p>
                    <p>If you have any question, please <a href="/#contact">Contact Us</a></p>
                    <div>At Li10, our mission is to help organizations reduce the carbon footprint associated with cloud computing. And we are doing so with the utmost respect for your business, your privacy and applicable laws. The following documents clarify our commitment and responsibilities.
                      <ul><a href="/legal/privacy-policy">Privacy Policy Statement</a></ul>
                      <ul><a href="/legal/terms-and-conditions">Terms & Conditions</a></ul>
                      <ul><a href="/legal/gdpr">GDPR Commitment</a></ul>
                      <ul><a href="/legal/data-subprocessor">Data Subprocessors</a></ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    );
}

