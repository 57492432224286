// import React from "react";
import { useState } from "react";

export function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [buttonMessage, setButtonMessage] = useState("Let's Connect!");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  let handleSubmit = async (e: any) => {
    // console.log("sending req to contact api", this.state)
    setButtonDisabled(true)
    e.preventDefault();
    try {
      let res = await fetch("https://api.li10.com/contact", {
        method: "POST",
        body: JSON.stringify({
          origin: "contact",
          name: name,
          email: email,
          phone: phone,
          company: company,
          message: message
        }),
      });

      // let resJson = await res.json();
      if (res.status === 200) {
        // console.log("200", resJson)
        setButtonMessage("Got it!")
      } else {
        // console.log("error", resJson)
        setButtonDisabled(false)
        setButtonMessage("An error occured, let's try again")
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="site-section bg-light" id="contact">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="section-title mb-3">Contact</h2>
          </div>
        </div>
        <div className="row aos-init aos-animate" data-aos="fade-up">
					<div className="mx-auto col-10 col-md-8 col-lg-6">
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <label htmlFor="inputName" className="col-sm-2 col-form-label">Name*</label>
              <div className="col-lg-10">
                <input type="text" className="form-control" id="inputName" onChange={(e) => setName(e.target.value)} required/>
                <div className="invalid-feedback">Please provide a name.</div>
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="inputEmail" className="col-sm-2 col-form-label">Email*</label>
              <div className="col-lg-10">
                <input type="email" className="form-control" id="inputEmail" onChange={(e) => setEmail(e.target.value)} required/>
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Company</label>
              <div className="col-lg-10">
                <input type="text" className="form-control" id="inputCompany" onChange={(e) => setCompany(e.target.value)}/>
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="inputPhone" className="col-sm-2 col-form-label">Phone</label>
              <div className="col-lg-10">
                <input type="text" className="form-control" id="inputPhone" onChange={(e) => setPhone(e.target.value)}/>
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="inputMessage" className="col-sm-2 col-form-label">Message*</label>
              <div className="col-lg-10">
                <textarea className="form-control" id="inputMessage" onChange={(e) => setMessage(e.target.value)}/>
              </div>
            </div>
						<div className="text-center">
            	<button type="submit" className="btn btn-primary mb-5" disabled={buttonDisabled}><b>{buttonMessage}</b></button>
						</div>
          </form>
					</div>
        </div>
      </div>
    </div>
  )
}
