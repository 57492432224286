import React, { useEffect } from "react";

import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import error from "../assets/404error.webp"
import { Link } from "react-router-dom";

export const PageNotFound = () => {

// function PageNotFound() {
    useEffect(() => {
        // Set the HTTP response status to 404
        document.title = "404 - Page Not Found";
        return () => {
            // Clean up any changes when the component unmounts
            document.title = "Li10"; // Restore the original title
            document.body.style.backgroundColor = "inherit"; // Restore the original background color
        };
    }, []);
    return (
        <div>
        <Header />
        <div className="site-section bg-light" id="about">
        <div className="container">
            <div className="row mb-5">
            <div className="col-12 text-center">
                <h2 className="section-title mb-3">Ooops</h2>
            </div>
            </div>
            <div className="row mb-5 aos-init aos-animate" data-aos="fade-up">
            <div className="col-lg-6">
                <img src={error} title="404 - PageNotFound" alt="PageNotFound" className="img-fluid mb-5 mb-lg-0 rounded shadow"></img>
            </div>
            <div className="col-lg-5 ml-auto pl-lg-5">
                <h2 className="text-black mb-4">Page Not Found</h2>
                <p className="mb-4">Go back to our <Link to="/">Main Page</Link></p>
            </div>
            </div>
        </div>
        </div>
        <Footer />
        </div>
    );
}