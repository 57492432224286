import {Header} from "../../components/Header";
import {Navbar} from "../../components/Navbar";

// function PrivacyPolicy() {
  export const PrivacyPolicy = () => {  
    return (
        <div>
        <Header />
        <Navbar />
        <div className="site-blocks-cover">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div className="row mb-4">
                  <div className="d-flex align-items-start flex-column" style={{paddingTop: "80px"}}>
                    <h1>Privacy Policy</h1>
                    <p>Li10 UG (”The Company“) respects its Users’ privacy and is committed to protecting any personal information shared with The Company. This statement describes how The Company uses personal information shared by Users.</p>
                    <h2>Personal Information</h2>
                    <p>Personal information means any information that may be used to identify an individual, such as a first and last name, email address, phone number, a home, postal or other physical address, other contact information, title, occupation, industry, personal interests, and other information. The Company does not automatically collect personal information, including Users’ email address. The Company does log Users’ IP addresses, but does not link IP addresses to any personal information unless personal information was voluntarily provided by a User and the User was logged into one of the Company’s products. The Site may use standard technologies, such as “cookies” and other HTML tools, to collect information about the use of the Site. The Company may collect personal information when such is voluntarily provided by Users (for example: if Users request further information, register to a mailing list, etc.) Also, The Company may receive personal information from business partners and vendors. The Company only uses such information if it has been collected in accordance with acceptable privacy practices consistent with this Privacy Statement and applicable laws. Access to certain web pages of The Company requires a login and a password. Unless the User requests deactivation of his or her personal information as specified below, his or her personal information may be retained by The Company to verify compliance with any applicable agreements and understandings, log software licenses granted, track software downloaded from those pages, or track usage of other applications available on those pages.</p>

                    <h2>Non Personal Information</h2>
                    <p>The information collected from our customers cloud environments, which does not include Personally Identifiable Information (PII), is solely utilized to further The Company's mission of reducing the environmental footprint of our customers. This data is exclusively used for this purpose and is not shared with third parties or used for any other means.</p>
                    <h2>Notice</h2>
                    <p>When personal information is collected, The Company will so inform Users and shall also inform the Users of the purpose of the collection. The Company will not transfer Users’ personal information to third parties without their specific consent, except under the limited conditions described under the discussion entitled “Information Sharing and Disclosure” below. The Company provides its Users with the opportunity to “opt in” prior to receiving direct marketing or market research information. In other words, The Company will require its Users’ affirmative action before it may use their information for purposes other than the purpose for which it was submitted. At all times, The Company will always give its Users the opportunity to “opt out” of receiving such materials.</p>

                    {/* <h2>Cookies and Other Tracking Technologies</h2>
                    <p>The Company uses common Internet technologies, such as cookies and IP addresses, to keep track of interactions with the Company's website and software.</p>

                    <h2>What are cookies?</h2>
                    <p>This Cookie Policy explains what cookies are and how we use them, the types of cookies we use i.e, the information we collect using cookies and how that information is used, and how to manage the cookie settings.</p>
                    <p>Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</p> */}

                    {/* <h2>How do we use cookies?</h2>
                    <p>As most of the online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data.</p>

                    <p>The third-party cookies used on our website are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.</p> */}

                    {/* <h2>Manage cookie preferences</h2> */}

                    {/* <h3>IP Addresses</h3>
                    <p>When you visit The Company’s informational websites, execute a function from your User account’s Li10 web portal, or launch Composer and Player, The Company collects your device’s Internet Protocol (“IP”) address. Doing so enables The Company 1) for informational websites: to track and aggregate non-personal information, 2) for User account’s Li10 web portal: to track deploy and share activities, 3) for Composer/Player: to check license status and product stability. For example, The Company uses IP addresses to monitor the regions from which Customers and Visitors navigate the Company’s websites.</p>

                    <h3>Mac Address and Serial Number</h3>
                    <p>When you launch a not-yet activated Player on a device, The Company may store the device Mac address or Serial Number expressly to accelerate future Player activation. As soon as Player is activated - or after few hours of non-activation - both the Mac Address and the Serial Number are deleted.</p>

                    <h2>How The Company May Use Information Collected</h2>
                    <p>The Company uses information for several general purposes: to fulfill Users’ requests for certain products and services, to personalize Users’ experiences on the Site, to maintain ongoing contacts with its Users, to improve its products using crash report information, to provide an Audit Trail to a subset of Users, and to better understand its Users’ needs and provide them with better services. The Company may also use Users’ information to send them, using internal or third-party platforms, direct marketing information or contact them for market research.</p> */}

                    <h2>Information Sharing and Disclosure</h2>
                    <p>Users’ personal information is never shared with third parties without the Users’ permission, except under any of the following circumstances.</p>
                    <p>
                      <ul>
                        <li>When a User has expressed his or her consent to share such information.</li>
                        <li>If sharing such information is necessary to provide a product or service requested by a User. In which case, The Company will only provide to such third parties the information they need to deliver the service. Also, such companies are prohibited from using Users’ information for any other purpose.</li>
                        {/* <li>As part of a joint sales promotion or to pass sales leads to one of The Company’s distribution partners;</li> */}
                        <li>If required to do so by law, to enforce The Company’s Terms of Use, or in urgent circumstances, to protect personal safety, the public or the Site.</li>
                      </ul>
                    </p>

                    <h2>Deactivation of User Information</h2>
                    <p>A User may request deactivation of his or her account or any of his or her personal information held by us by sending an email to matt@li10.com. If a User chooses to deactivate his or her account, The Company may retain in its files some personal information to prevent fraud, resolve disputes, troubleshoot problems, enforce its Terms of Use, and comply with legal requirements as permitted by law.</p>

                    <h2>Data Security</h2>
                    <p>User account information is password-protected. The Company shall safeguard the security of data provided by Users with physical, electronic, and managerial procedures. In certain areas of our websites, The Company uses industry-standard TLS-encryption to enhance the security of sensitive data transmissions.</p>

                    <h2>Third Party Sites</h2>
                    <p>The Company’s website contains links to other sites. The Company does not share its Users’ personal information with those websites and is not responsible for their privacy practices.</p>

                    <h2>Changes to this Privacy Statement</h2>
                    <p>The Company may amend this privacy statement from time to time. In case any substantial changes are made to this Privacy Statement, The Company will make that information known to its Users by posting a notice on the Site.</p>

                    <h2>Questions or Suggestions</h2>
                    <p>Any questions or concerns about The Company’s privacy policy may be e-mailed to matt@li10.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    );
}

