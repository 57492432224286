import React from "react"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { 
  FaCheck
} from "react-icons/fa";


export const Pricing = () => {
  // render() {
  return (
    <div className="site-section bg-light" id="pricing">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="section-title">Pricing</h2>
          </div>
        </div>
        <div className="row align-items-stretch" style={{backgroundColor: "#e9ecef", border: "1px solid #dee2e6", }}> 
        {/*  padding: "20px", */}
          <div className="px-3 pt-3 aos-init aos-animate" data-aos="fade-up">
            <div className="unit-4 d-block">
              <div className="mb-3">
                <center><h3><b>Starter</b>: Free if your total monthly cloud spend is under $5000</h3></center>
              </div>

              <div>
                <p>
                  <FaCheck /> &nbsp;Free access to all SaaS services<br />
                  <FaCheck /> &nbsp;Monitor your emissions with the Li10 CO2 Dashboard<br />
                  <FaCheck /> &nbsp;Track and reduce waste with the Li10 Usage Report<br />
                  <FaCheck /> &nbsp;Free access to our open source projects<br />
                  <FaCheck /> &nbsp;Single-user access to our SaaS
                </p>
              </div>
            </div>
          </div>

          <div className="p-3 aos-init aos-animate" data-aos="fade-up">
            <div className="unit-4 d-block">
              <div className="mb-3">
                <center><h3><b>Enterprise</b>: 0.1% of your monthly cloud bill per feature</h3></center>
              </div>
              <div>
                <p>
                  Same features as the Starter pack, plus: <br />
                  <FaCheck /> &nbsp;SSO access with your corporate Identify Provider (e.g. Okta)<br />
                  <FaCheck /> &nbsp;1-hour weekly advisory with a Li10 engineer<br />
                  <FaCheck /> &nbsp;Support for our open source solutions<br />
                  <FaCheck /> &nbsp;Paid features available: Li10 CO2 Dashboard, Li10 Usage Report
                </p>
                <p>If your AWS monthly bill is $10,000 and you enable both the Li10 CO2 Dashboard and the Li10 Usage Report, your Li10 fee will be $20 per month.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  // }
}

// export default Pricing;
