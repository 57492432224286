import {Header} from "../../components/Header";
import {Navbar} from "../../components/Navbar";

// function DataSubProcessor() {
export const DataSubProcessor = () => {
  const data = [
    { id: 1,
      entity: 'Amazon Web Services EMEA SARL',
      desc: "Cloud Provider",
      data: 'Li10 services run on top of AWS. This cloud provider hosts email addresses and non personal metadata your cloud environment.' },

    { id: 2,
      entity: 'MongoDB, Inc.',
      desc: "Database as a service, on top of AWS",
      data: 'Li10 CO2 Monitor stores summarized non-personal billing data from your cloud environment and its associated CO2.' },

    { id: 3,
      entity: 'Google Inc.',
      desc: 'Email support',
      data:"Li10 uses Google Workspace for email communication. If you include personal data in your email (e.g., snippets of database logs, query outputs, etc.), this information is also processed by Google. "}
  ];

  // Gitlab: no customer data
  // Slack: no customer data
  //

    return (
        <div>
        <Header />
        <Navbar />
        <div className="site-blocks-cover">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div className="row mb-4">
                  <div className="d-flex align-items-start flex-column">
                    <h1>Data Subprocessor</h1>
                    <p>The table below contains an up-to-date list of Subprocessors authorized to process customer data in support of the services offered by Li10 UG (”The Company“). The Company imposes data protection terms with each Subprocessor regarding their security controls and applicable regulations for the protection of personal data.</p>
                    <p>Last Updated on May 30th, 2024</p>

                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Third-Party Entity</th>
                          <th>Description of Processing</th>
                          <th>Data Types</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item) => (
                          <tr key={item.id}>
                            <td>{item.entity}</td>
                            <td>{item.desc}</td>
                            <td>{item.data}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    );
}
export default DataSubProcessor;
