// import React from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import React from "react";

// export const Footer = () => {
export class Footer extends React.Component {
    render() {
      return (
        <div className="footer py-1 border-top text-center" id="contact-section">
          <div className="container">
            <div className="row">
              {/* <div className="col-12">
                <p className="mb-0">
                <a href="/" className="p-4"><FontAwesomeIcon icon={brands('facebook')} /></a>
                <a href="/" className="p-4"><FontAwesomeIcon icon={brands('twitter')} /></a>
                <a href="/" className="p-4"><FontAwesomeIcon icon={brands('linkedin')} /></a>
                </p>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="mb-0">Copyright ©2025 All rights reserved | <a href="/legal">Legal</a></p>
              </div>
            </div>
          </div>
        </div>
      );
    }
}
